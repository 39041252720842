import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class APIService {
  // public _baseUrl: String = 'https://q8qnfn29xi.execute-api.ap-south-1.amazonaws.com/QA/';
    public _baseUrl: String = 'https://api.thepurchasehouse.com/v1/';
    public _locationBaseUrl: String = 'https://api.ipregistry.co/?key=tryout';

    public _baseUrlUniSearch: String = 'https://api.thepurchasehouse.com/'
    public accountDetails: any;
    public paginationDetail;
    public projectDetails: any;
    private previousUrl: string = undefined;
    private currentUrl: string = undefined;
    private subject = new Subject<any>();
    private userDetails = new BehaviorSubject<string>('');
    currentUserDetails = this.userDetails.asObservable();
    link: HTMLLinkElement;

    private searchInputToggle = new BehaviorSubject<string>('');
    searchInputStatus = this.searchInputToggle.asObservable();

    constructor(@Inject(DOCUMENT) private dom,private http: HttpClient, private title: Title, private meta: Meta,private router: Router) {
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
          }
        });

        this.link = this.dom.createElement('link');
        this.link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(this.link );
    }
    
    public getPreviousUrl() {
        return [this.previousUrl,this.currentUrl];
    }

    createCanonicalURL() {
      this.link.setAttribute('href', this.dom.URL);
   }

    // update meta tags
    updateTags(productObj: any) {
         let keyword = productObj.productName+", New "+ productObj.productName +" online, Engineering, Surplus Stock, Excess Inventory, Used or Second hand machines, Industrial Plants for sell" 
         this.meta.updateTag({name: 'keywords', content: keyword});
         let description = productObj.productName +" – Buy Sell "+ productObj.productName +" Online at lowest prices in India. Buy Sell "+productObj.productDescription +", "+productObj.productBrand +", "+productObj.productLoc;
         this.meta.updateTag({name: 'description', content: description});
         let metaTitle=  productObj.productName +"– Buy Sell "+ productObj.productName +" Online at Best Price in India-The Purchase House";
         this.meta.updateTag({name: 'title', content: metaTitle});

         //this.titleService.setTitle('My awesome app');
    }

    setInpustSearch(data){
      this.searchInputToggle.next(data);
  }

    sendMessage(message: string) {
        this.subject.next({ text: message });
    }

    clearMessages() {
        this.subject.next();
    }
    getMessage() {
        return this.subject.asObservable();
    }

    setUserDetails(data){
        this.userDetails.next(data);
    }
    clearUserDetails() {
        this.userDetails.next('');
    }
   setPaginationDetails(data:any){   
        this.paginationDetail=data;
   };
 
   getPaginationDetails(){ 
     return this.paginationDetail;
   };

   clearPaginationDetails(){
    this.paginationDetail="";
  };

    getAll(url: string) {
        return this
            .http
            .get(this._baseUrl + url);
    }

    getById(url: string, data: any) {
        return this
            .http
            .post(this._baseUrl + url, data);
    }
    postData(url: string, data: any) {
        return this
            .http
            .post(this._baseUrl + url, data);
    }

    getSearchAll(url: string) {
        return this
            .http
            .get(this._baseUrlUniSearch + url);
    }

    seeDetailProductviewCounter(obj: any){
        let postData = {userId:null,productCode:obj.productCode,productId:obj.productId};
        this.postData("productview-counter", postData).subscribe(
          (data: any) => {
           console.log(data)
          },
          err => {
            console.log(err);
          }
        );
      }

    getCurrentLocation() {
        return this
            .http
            .get(this._locationBaseUrl+'');
    }

      // fetch()
      // .then(function (response) {
      //     return response.json();
      // })
      // .then(function (payload) {
      //     console.log(payload.location.country.name + ', ' + payload.location.city);
      // });

}
