import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './customers-modules/customer-app.module#CustomerAppModule' },
  { path: 'users', loadChildren: './users-modules/user-app.module#UserAppModule' },
 { path: 'page-not-found', loadChildren: './page-not-found-modules/page-not-found-app.module#PageNotFoundAppModule' },
 { path: '**', redirectTo: 'page-not-found' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
