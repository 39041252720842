import { BrowserModule } from "@angular/platform-browser";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgxLoadingModule } from "ngx-loading";
import { GaugeChartModule } from "angular-gauge-chart";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { APIService } from "./services/service.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { UploadService } from "./services/upload.service";
import { AuthGuard } from "./services/auth.guard";
import { FilterModule } from "./shared/filter.module";
import { BuyerLoginRegistrationComponent } from './common/buyer-login-registration/buyer-login-registration.component';
import { SearchTradingComponentComponent } from "./common/search-trading-component/search-trading-component.component";


@NgModule({
  declarations: [
    //SearchTradingComponentComponent,
    //  GaugeChartComponent,
    AppComponent,
   // FilterPipe
  ],
  imports: [
    GaugeChartModule,
    BrowserModule,
    HttpClientModule,
    NgxLoadingModule.forRoot({}),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
    }), // ToastrModule added
    AppRoutingModule,
    NgbModule,
    FormsModule,
    FilterModule
  ],
  providers: [
    APIService,
    UploadService,
    AuthGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  // exports: [
  //   FilterPipe
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {}
