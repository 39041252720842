import {NgModule} from '@angular/core';

import { FilterPipe } from "../shared/filter";

@NgModule({
  declarations:[FilterPipe],
  exports:[FilterPipe]
})
export class FilterModule
{
}