import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
// import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APIService } from '../services/service.component';
@Injectable({
    providedIn: 'root'
})
export class UploadService {
    private subject = new Subject<any>();
    // FOLDER = 'media/';
    // public s3Response = [];
    mediaData: any;
    pipe = new DatePipe('en-US'); // Use your own locale
    public _baseUrl: String = 'https://jkspiyfumd.execute-api.ap-south-1.amazonaws.com/qa/'; //prod
    constructor(private http: HttpClient, public _service: APIService) { }

    clearMessage() {
       // this.subject.next('');
        this.subject.next({ data: undefined })
        this.subject.unsubscribe();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    sendMessage(message: string) {
        this.subject.next({ data: message });
    }

    setMediaData(data){
        this.mediaData = data;
    }

    getMediaData(){
        return this.mediaData;
    }

    // uploadFile(files: any, formData: any, resData: any) {

    //     let s3Response = [];

    //     let currentDate: any = new Date();
    //     const timeStampDate = Math.round(currentDate / 1000);
    //     console.log(timeStampDate);

    //     const now = Date.now();
    //     const myFormattedDate = this.pipe.transform(now, 'yyyyMMddHHMMSSMI');
    //     let that = this;
    //     let count=0;
    //     files.forEach(function (value, index) {
    //         console.log('file', files);
    //         let FOLDER = 'media/';
    //         let FILENAME ='';
    //         const contentType = value.type;
    //         if (contentType == 'video/mp4' || contentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || contentType == 'application/vnd.ms-excel') {
    //             FOLDER = FOLDER + 'video/';
    //             FILENAME = formData.userId + '_' + resData.productCode + '_' + timeStampDate + '_' + index + '.' + value.name.split('.').pop(); //4_TPHIR000000004_prodTitle_YYYYMMDDHHMINSS.mp4
    //         } else {
    //             FOLDER = FOLDER + 'images/' 
    //             FILENAME = formData.userId + '_' + resData.productCode + '_' + timeStampDate + '_' + index + '.' + value.name.split('.').pop();
    //         }
    //         console.log(contentType);

    //         // const params = {
    //         //     Bucket: 'tph-doc-prod', //'tph-doc', 
    //         //     Key: FOLDER,
    //         //     // Key: FOLDER + value.name, 
    //         //     Body: value,
    //         //     ACL: 'public-read',
    //         //     ContentType: contentType
    //         // };

    //         var request = {
    //             bucket_name: 'tph-doc-prod',
    //             folder_name: FOLDER,
    //             file_name:FILENAME,
    //             inputStream: value
    //           };


    //         this.
    //         _service.postData('upload-ap', request)
    //         .subscribe((data: any) => {
    //             console.log(data)
    //                 if (data.status === 200) {

    //                 }
    //             })

    //         //  bucket.upload(params, function (err, data) {
    //         //     count = count + 1;
    //         //     if (err) {
    //         //         console.log('There was an error uploading your file: ', err);
    //         //         return false;
    //         //     }
    //         //     if (contentType == 'video/mp4' || contentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || contentType == 'application/vnd.ms-excel') {
    //         //          s3Response.push({
    //         //             "path": data.Key,
    //         //             "itemId": 2,
    //         //             "fileName": data.Key
    //         //         });
    //         //     } else {
    //         //          s3Response.push({
    //         //             "path": data.key,
    //         //             "itemId": 1,
    //         //             "fileName": data.key
    //         //         });
    //         //     }



    //         //    // console.log('Successfully uploaded file.', data);
    //         //     if (files.length === count) {
    //         //         console.log('all Successfully uploaded file.', s3Response);
                
    //         //         return that.subject.next({ data: s3Response });

    //         //       //  return this.mediaData = s3Response;

    //         //     }

    //         // })


    //     });


    // }


    uploadFile(files: any, formData: any, resData: any) {

        let s3Response = [];
        let currentDate: any = new Date();
        const timeStampDate = Math.round(currentDate / 1000);
        console.log(timeStampDate);
    
        const now = Date.now();
        const myFormattedDate = this.pipe.transform(now, 'yyyyMMddHHMMSSMI');
        let that = this;
        let count=0;
        for(let i=0;i<files.length;i++){
    
            let FOLDER = 'media/';
            let FILENAME ='';
            const contentType = files[i].split(';')[0].split('/')[1];
         //   console.log('files[i]', files[i]);
            if (contentType == 'mp4' || contentType == 'octet-stream' || contentType == 'application/vnd.ms-excel') {
                FOLDER = FOLDER + 'video';
                if(contentType == 'octet-stream'){
                    FILENAME = formData.userId + '_' + resData.productCode + '_' + timeStampDate + '_' + i + '.xlsx';
                } else {
                    FILENAME = formData.userId + '_' + resData.productCode + '_' + timeStampDate + '_' + i + '.' + contentType; //4_TPHIR000000004_prodTitle_YYYYMMDDHHMINSS.mp4
                }
                
            } else {
                FOLDER = FOLDER + 'images' 
                FILENAME = formData.userId + '_' + resData.productCode + '_' + timeStampDate + '_' + i + '.' +contentType//+ value.name.split('.').pop();
            }
    
            var request = {
                bucket_name: 'tph-doc-prod',
                folder_name: FOLDER,
                file_name:FILENAME,
                inputStream: files[i].replace(/^[^,]*,/, "")
              };
    
            this.postData('upload-api', request).subscribe((data:any) => {
                count = count + 1;
                if (contentType == 'mp4' || contentType == 'octet-stream' || contentType == 'application/vnd.ms-excel') {
                  s3Response.push({
                     "path": data.foldername,
                     "itemId": 2,
                     "fileName": data.fileName
                 });
             } else {
                  s3Response.push({
                     "path": data.foldername,
                     "itemId": 1,
                     "fileName": data.fileName
                 });
             }
             if (files.length === count) {
              //   console.log('all Successfully uploaded file.', s3Response);
              return that.subject.next({ data: s3Response });
             }
            });
    
    
        }
    
    }

    postData(url: string, data: any) {
        return this
            .http
            .post(this._baseUrl + url, data);
    }


    renameFilename(file: any, formData: any, res: any) {
        const now = Date.now();
        const myFormattedDate = this.pipe.transform(now, 'yyyyMMddHHMMSS');
        return formData.userId + '_' + res.productCode + '_' + formData.prodTitle + '_' + myFormattedDate + file.split('.').pop();
    }
}
