import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, filters: string[]): any[] {
        // if (!items) return [];
        // if (!searchText) return items;
        // console.log(searchText);
        // searchText = searchText.toLowerCase();
        // return items.filter(it => {
        //   for(let ob in it){
        //    // console.log(it[ob])
        //    if(it[ob]!=undefined && it[ob]!=null)
        //    console.log(it[ob])
        //   }
    
        //   //return it.Title.toLowerCase().includes(searchText);
        // });
        if (!items) return [];
        if (!searchText) return items;
    
        searchText = searchText.toLowerCase();
    
        let filteredItems = [];
    
        items.forEach(item => {
          filters.forEach(filter => {
            if (item[filter] != undefined && item[filter].toString().toLowerCase().includes(searchText)) {
              filteredItems.push(item);
            }
          })
        })
        return filteredItems;
      }
}